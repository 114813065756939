var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"nuevo":false}}),_c('section',[_c('article',{staticStyle:{"height":"395px"}},[_c('field',{staticStyle:{"top":"5px","left":"5px"},attrs:{"name":"ndoc","widget":"char","searchable":"","label":"Nº Devolución","width":"110px"}}),_c('field',{staticStyle:{"top":"5px","left":"130px"},attrs:{"name":"fecha","type":"date","label":"Fecha","width":"100px"}}),_c('field',{ref:"proveedor_id",staticStyle:{"top":"5px","right":"55px"},attrs:{"autofocus":"","name":"proveedor_id","widget":"m2o","label":"Proveedor","searchable":"","width":"320px","labelProp":"nombre_comercial","imgProp":"imagen.imagen","fields":[
          'nacionalidad',
          {name:'divisa_id',fields:['codigo','nombre','ratio']},
          'tipo_impuesto',
          'iva',
          're',
          'descuento_especial',
          'financiacion',
          {name:'comentarios',fields:['descripcion','fecha','activo']},
          {name:'forma_pago_id',fields:['codigo','descripcion','vencimientos','periodicidad','periodicidad_unidades']},
          'descuento_linea_unidad',
          'descuento_linea_peso']}}),_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"40px","height":"353px"},attrs:{"wt":_vm.formData.wt,"buttons":[
          'Cabecera',
          'Líneas'
        ],"classButton":{disabled:!_vm.formData.proveedor_id}}},[_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"10px","left":"15px"},attrs:{"name":"total_unidades","widget":"float","type":"number","dec":"2","label":"Total Unidades","width":"80px","readonly":true}}),_c('field',{staticStyle:{"top":"10px","left":"115px"},attrs:{"name":"total_peso","widget":"float","type":"number","dec":"3","label":"Total Peso","width":"80px","readonly":true}}),_c('field',{staticStyle:{"top":"10px","left":"200px"},attrs:{"name":"total_articulos","widget":"float","type":"number","dec":"2","label":"Total Artículos","width":"80px","readonly":true}}),_c('field',{staticStyle:{"top":"168px","left":"11px"},attrs:{"name":"adjuntos","max":6,"widget":"files","label":"Adjuntos"}}),_c('field',{staticStyle:{"top":"168px","right":"11px"},attrs:{"name":"observaciones","widget":"text","label":"Observaciones","width":"429px","height":"40px"}})],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"lineas",staticClass:"lineas",staticStyle:{"top":"5px","left":"4px","width":"648px"},attrs:{"name":"lineas","htSettings":{rowHeights:15},"widget":"handsontable","height":303,"minRows":18,"customprop":"lineas_customprop","readonly":"","fields":[{name:'origin_albaran_id',fields:['ndoc']}],"columns":[
              {name:'ean13',header:'|||||||||'},
              {name:'articulo_id',header:'Artículo', type: 'm2o', primary:'codigo', label:'codigo', noOpen: true, fields:[{name:'thumbnail',fields:['imagen']}]},
              {name:'descripcion',header:'Descripción'},
              {name:'modelo_id',header:'Modelo', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
              {name:'refproveedor',header:'R. Proveedor'},
              //{name:'familia_id',header:'Familia', type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
              //{name:'tt',header:'T', type:'select', options:['A','R']},
              {name:'almacen_id',header:'Almacén', type:'m2o', primary:'codigo', readOnly: true},
              {name:'unidades',header:'Unid.',type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'etiqueta',header:'Etiq.', type: 'numeric'},
              {name:'peso',header:'Peso',type:'numeric',numericFormat: { pattern: '0,0.000'}},
              {name:'c',header:'C', type:'select', options:['U','P']},
              {name:'m',header:'M', type:'checkbox'},
              {name:'r',header:'R', type:'checkbox'},

              {name:'precio',header:'Precio',type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'descuentos',header:'Dtos.',type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
              {name:'totallinea',header:'Total Línea',type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'m2',header:'M', type:'checkbox'},
              {name:'c2',header:'C', type:'checkbox'},
              {name:'o',header:'O', type:'checkbox'},
              {name:'precio_coste',header:'P. Coste',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'coste_fijo',header:'Coste Fijo',type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'taqueria_ids',header:'Taquería',type:'m2m',primary:'codigo', image: 'thumbnail.imagen', label: 'codigo', fields:['precio_coste'], filter: function (){ return ['seccion_id','=','PCK']; }},
              {name:'taqueria_precio',header:'Taq.(€)',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'incluir_taqueria',header:'P', type:'checkbox'},
              {name:'otros_costes',header:'O. Costes',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
              {name:'v',header:'V', type:'select', options:['U','P']},

              {name:'m3',header:'M', type:'checkbox'} ]}})],1)])],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_vm._v(" Listado "),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }